import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Select = (props) => {
    const { label, name, options, isMulti = true, defaultValue, ...rest } = props;
  return (
    <div className='form-control space-x-2'>
        <label htmlFor={name}>{label}</label>
        <Field as='select' id={name} name={name} placeholder={defaultValue} isMulti={isMulti} className="rounded-md border-2 border-border" {...rest}>
            {/* {defaultValue && <option defaultValue>{defaultValue}</option>} */}
            {options.map(option => {
                return (
                    <option key={option.value} value={option.value} disabled={option.disabled}>
                        {option.key}
                    </option>
                )
            })}
        </Field> 
        <ErrorMessage name={name}>
            <TextError />
        </ErrorMessage>
    </div>
  )
}

export default Select;
