import { Field } from 'formik';

const MultipleSelectField = ({ question, values }) => (
    <div>
        <h3>{question.questionText}</h3>
        <div role="group" className='flex flex-col' aria-labelledby={question.questionText}>
            {question.choices.map((choice) => (
                <label key={choice.id}>
                    <Field
                        type="checkbox"
                        name={`question${question.questionId}`}
                        value={choice.id}
                        checked={values[`question${question.questionId}`]?.includes(`${choice.id}`)}
                    />
                    {choice.text}
                </label>
            ))}
        </div>
    </div>
);

export default MultipleSelectField;
