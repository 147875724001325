import { call, put, takeLatest, select } from 'redux-saga/effects';
import { axiosPublic } from '../../api';
import { ACTION } from '../action';
import party from 'party-js';

function* fetchInitBg() {
    const { patient } = yield select(state => state.patient)
    if (patient.patient_id) {
        const { data } = yield call(axiosPublic.get, `/patient-bg-reading/latest-bg-set/${patient.patient_id}`);
        const fetch_next_session = data.sequence_info.upcomingDate ? true : false
        if (fetch_next_session) {
            yield put({
                type: ACTION.UPDATE_BG_STATE,
                payload: {
                    blood_glucose_readings: data.blood_glucose_readings,
                    reading_sessions: data.reading_sessions,
                    fetch_next_session: true,
                    session_details: {
                        ...data.sequence_info
                    }
                }
            })
        } else {
            yield put({
                type: ACTION.UPDATE_BG_STATE,
                payload: {
                    blood_glucose_readings: data.blood_glucose_readings,
                    reading_sessions: data.reading_sessions
                }
            })
        }
    }
}

function* updateBgReadingFromOrigin() {
    const { patient } = yield select(state => state.patient);
    const { data } = yield call(axiosPublic.get, `/patient-bg-reading/latest-bg-set/${patient.patient_id}`);
    yield put({
        type: ACTION.UPDATE_BG_STATE,
        payload: {   
            blood_glucose_readings: data.blood_glucose_readings,
            reading_sessions: data.reading_sessions,
            session_details: data.sequence_info,
            fetch_next_session: data.sequence_info.upcomingSequenceId ? true: false
        }
    })
}

function* initiateBgSession({ payload }) {
    const { patient } = yield select(state => state.patient)
    const packet = {
        // "initiateBgSet": true,
        "startDate": payload.startDate,
        "readingSetType": payload.readingSetType,
        "patient_id": patient.patient_id
    }
    const { data } = yield call(axiosPublic.post, `/patient-bg-reading/initiate-bg-set`, packet)
    if (data) {
        yield call(updateBgReadingFromOrigin)
    }
    yield put({
        type: ACTION.UPDATE_BG_STATE,
        payload: {
            fetch_next_session: true,
            session_details: {
                ...data
            }
        }
    })
}

function* abortBgSession() {
    const { patient } = yield select(state => state.patient)
    const patientBG = yield select(state => state.patientBG)
    const packet = {
        "startDate": patientBG.session_details.startDate,
        "readingSetType": patientBG.session_details.readingSetType
    }
    const { data } = yield call(axiosPublic.put, `/patient-bg-reading/latest-bg-set/${patient.patient_id}/abandon`, packet);
    if (data) {
        yield call(updateBgReadingFromOrigin)
        yield put({
            type: ACTION.UPDATE_BG_STATE,
            payload: {
                fetch_next_session: false,
                session_details: null
            }
        });
    }
}

function* restartBgSession() {
    const { patient } = yield select(state => state.patient)
    const patientBG = yield select(state => state.patientBG)
    const packet = {
        "startDate": patientBG.session_details.startDate,
        "readingSetType": patientBG.session_details.readingSetType
    }
    const { data } = yield call(axiosPublic.put, `/patient-bg-reading/latest-bg-set/${patient.patient_id}/restart`, packet);
    if (data) {
        yield call(updateBgReadingFromOrigin);
    }
}

function* recordBg({ payload }) {
    yield put({
        type: ACTION.UPDATE_BG_STATE,
        payload: {
            record_bg_trans_status: true,
        }
    })
    const { patient } = yield select(state => state.patient)
    const patientBG = yield select(state => state.patientBG)
    const packet = {
        patientId: patient.patient_id,
        "sequenceId": patientBG.session_details.upcomingSequenceId,
        "startDate": patientBG.session_details.startDate,
        "session": patientBG.session_details.upcomingSession,
        "reading": payload.value,
        "readingDate": patientBG.session_details.upcomingDate,
        "readingSetType": patientBG.session_details.readingSetType,
    }
    const { data } = yield call(axiosPublic.post, `/patient-bg-reading/insert-bg-record`, packet);
    if (data) {
        yield call(updateBgReadingFromOrigin)
    }
    if (data.upcomingDate) {
        yield put({
            type: ACTION.UPDATE_BG_STATE,
            payload: {
                fetch_next_session: true,
                record_bg_trans_status: false,
                session_details: {
                    ...data
                }
            }
        })
    } else {
        yield put({
            type: ACTION.UPDATE_BG_STATE,
            payload: {
                fetch_next_session: false,
                session_details: null,
                record_bg_trans_status: false
            }
        })
        yield put({
            type: ACTION.POST_MESSAGE_2,
            payload: {
                message: patientBG.completionMessage[Math.floor(Math.random() * patientBG.completionMessage.length)],
                interval: 5000
            }
        })
        party.confetti(document.getElementById('messageboard2'), {
            count: party.variation.range(40, 40),
        });
    }
}

function* redoPrevReading() {
    const { patient } = yield select(state => state.patient)
    const patientBG = yield select(state => state.patientBG)
    const packet = {
        "startDate": patientBG.session_details.startDate,
        "readingSetType": patientBG.session_details.readingSetType,
    }
    const { data } = yield call(axiosPublic.put, `/patient-bg-reading/latest-bg-set/${patient.patient_id}/edit-previous-reading`, packet);
    if (data) {
        yield call(updateBgReadingFromOrigin)
    }
}

export function* watchPatientBG() {
    yield takeLatest(ACTION.FETCH_INIT, fetchInitBg);
    yield takeLatest(ACTION.INITIATE_BG_SESSION, initiateBgSession);
    yield takeLatest(ACTION.RECORD_BG, recordBg);
    yield takeLatest(ACTION.RESTART_SESSION, restartBgSession);
    yield takeLatest(ACTION.ABORT_SESSION, abortBgSession);
    yield takeLatest(ACTION.REDO_PREVIOUS_READING, redoPrevReading);
}
