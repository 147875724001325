import React, { useEffect, useState } from 'react'
import { axiosPublic } from '../../api';
import Accordion from '../../components/ui/Accordion';
import Card from './Card';

const Cardset = () => {
    const [cardData, setCardData] = useState([]);

    useEffect(() => {
        axiosPublic('/hb-health-framework/patients/earliest-causes/53eb8d65-ffae-4cf1-aea4-97aa76618048')
            .then(res => {
                setCardData(res.data.data);
            })
    }, []);

    return (
        <div className=''>
            <p className='text-md sm:text-lg font-medium'>Here's what we're working on today</p>
            <div className='space-y-2 mt-2'>
                {
                    cardData.length ?
                        cardData.map((card, i) => <Card key={i} id={i} card={card} />)
                        :
                        <p className='text-customGray'> no records found!</p>
                }
            </div>
        </div>
    )
}

export default Cardset;

