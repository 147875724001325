import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./rootReducer";
import rootSaga from './rootSaga';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore({
    reducer: rootReducer,
    middleware
});

sagaMiddleware.run(rootSaga);

export default store;
