import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRotateRight, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import FormikControl from '../../components/formik/FormikControl';
import { ACTION } from '../../store/action';
import MoreOption from './MoreOption';
import ChartWrapper from './ChartWrapper';

const BloodGlucose = () => {

    const { patientBG } = useSelector(state => state);
    const dispatch = useDispatch();

    const initialValues = {
        bgValue: ""
    }

    const handleSkipBg = () => {
        dispatch({
            type: ACTION.RECORD_BG,
            payload: {
                value: null
            }
        })
    }

    const handleRedoPrev = () => {
        dispatch({
            type: ACTION.REDO_PREVIOUS_READING
        })
    }

    return (
        <div>
            <div className='flex flex-col sm:flex-row justify-between items-center' id='bgChart'>
                <div className='md:w-full w-full flex flex-col '>
                    <div className={`flex ${!patientBG.fetch_next_session && 'flex-col'} sm:flex-row w-full items-start justify-between p-2`}>
                        <div className='w-100'>
                            <p className='text-md sm:text-lg font-medium'>Blood Glucose</p>
                        </div>
                        <MoreOption />
                    </div>
                    <div className='' >
                        <ChartWrapper />
                    </div>
                    {patientBG.fetch_next_session ?
                        <>
                            <hr />
                            <div className='text-xs flex justify-center py-2'>
                                <div className='bg-lightGray w-[100%] rounded p-1 px-1 flex flex-row space-x-2 items-center'>
                                    <Formik
                                        initialValues={initialValues}
                                        enableReinitialize
                                        // validationSchema={validationSchema}
                                        onSubmit={(values, actions) => {
                                            if(!patientBG.record_bg_trans_status) {
                                                dispatch({
                                                    type: ACTION.RECORD_BG,
                                                    payload: {
                                                        value: parseInt(values.bgValue)
                                                    }
                                                })
                                                // saveNotes(values, actions)
                                                actions.setSubmitting(false);
                                                actions.resetForm();
                                            }
                                        }}
                                    >
                                        {({ isSubmitting, submitForm }) => (
                                            <Form className='flex flex-col sm:flex-row justify-between items-center sm:space-x-4'>
                                                <div className='set1 flex space-x-4' >
                                                    <p className=''>{patientBG.session_details.upcomingDate}</p>
                                                    <p>{patientBG.session_details.upcomingSession}</p>
                                                </div>
                                                <div className='flex space-x-2'>
                                                    <FormikControl key='bgValue' control='input' name='bgValue' placeholder="enter glucose reading" disabled={patientBG.record_bg_trans_status} />
                                                    {
                                                        patientBG.blood_glucose_readings.length
                                                            ? <div className="cursor-pointer bg-white flex items-center px-4 rounded" onClick={() => handleRedoPrev()} >
                                                                <FontAwesomeIcon icon={faArrowRotateLeft} className="text-customGray" />
                                                            </div>
                                                            : null
                                                    }
                                                    <div className="bg-primary flex items-center text-white font-bold px-4 rounded"  disabled={patientBG.record_bg_trans_status} onClick={() => {
                                                        if(!patientBG.record_bg_trans_status) {
                                                            submitForm();
                                                        }
                                                    }}>
                                                        {
                                                            patientBG.record_bg_trans_status 
                                                            ? (
                                                                <svg
                                                                    className="animate-spin mr-2 h-2 w-2 text-white inline-block"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <circle
                                                                        className="opacity-25"
                                                                        cx="12"
                                                                        cy="12"
                                                                        r="10"
                                                                        stroke="currentColor"
                                                                        strokeWidth="4"
                                                                    />
                                                                    <path
                                                                        className="opacity-75"
                                                                        fill="currentColor"
                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 018 4.109v3.982c0 .622.24 1.207.673 1.646L8 10.709l2.854-2.853a2 2 0 012.828 0l.708.708a2 2 0 010 2.828L11.999 14.7l1.853 1.853A2 2 0 0112.53 18H5.998zM19.294 8.293a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0l-1-1a1 1 0 011.414-1.414L14 10.586l2.293-2.293a1 1 0 011.414 0z"
                                                                    />
                                                                </svg>
                                                            ) 
                                                            : <FontAwesomeIcon icon={faCheck} className="text-white" />
                                                        }
                                                    </div>
                                                    <div className="cursor-pointer bg-white flex items-center px-4 rounded" onClick={handleSkipBg}>
                                                        <FontAwesomeIcon icon={faArrowRotateRight} className="text-customGray" />
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </> :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default BloodGlucose
