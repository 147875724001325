import { takeEvery, call, put, select, delay } from 'redux-saga/effects';
import { ACTION } from '../action';
import { axiosPublic } from '../../api';
// import { sendTransactionsToBackend } from './api';

function* triggerMessage({ payload }) {
    yield put({
        type: ACTION.POST_MESSAGE_STATE_2, state: {
            isActive: true,
            message: payload.message
        }
    });
    // yield delay(payload.interval);
    // yield put({
    //     type: ACTION.POST_MESSAGE_STATE_2, state: {
    //         message: '',
    //         isActive: true
    //     }

    // })
}

export function* watchMessage2() {
    yield takeEvery(ACTION.POST_MESSAGE_2, triggerMessage);
}