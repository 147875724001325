import { ACTION } from "../action";

const initialState = {
    blood_glucose_readings: [],
    reading_sessions: ['Before Breakfast', 'Before Lunch', 'Before Dinner', 'Before Bed', ''],
    next_sequence_id: null,
    fetch_next_session: false,
    session_details: null,
    completionMessage: ["Great job! You've  successfully completed blood glucose set. Keep up the good work!" , "Bravo!  You've  successfully recorded blood glucose readings. Your commitment is truly admirable", "Congratulations on completion of blood glucose set. Your commitment is truly inspiring"],
    record_bg_trans_status: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.INITIATE_BG_STATE:
            return {
                ...state,
                ...action.payload
            }
        case ACTION.UPDATE_BG_STATE:
            return { ...state, ...action.payload };
        case ACTION.UPDATE_TREE_STATUS:
            state.tree[action.payload.id].status = action.payload.status
            return { ...state }
        case ACTION.INITIATE_MAIN:
            state.mainboard = action.payload
            return { ...state }
        case ACTION.INITIATE_STATUS_TEMPLATE:
            state.statusTemplate = action.payload
            return { ...state }
        default:
            return state;
    }
};
