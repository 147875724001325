// patientBG
export const ACTION = {
    // patient
    ADD_PATIENT: 'ADD_PATIENT',
    ADD_PATIENT_STATE: 'ADD_PATIENT_STATE',
    UPDATE_PATIENT: 'UPDATE_PATIENT',
    DELETE_PATIENT: 'DELETE_PATIENT',

    // patient bg
    FETCH_INIT: 'FETCH_INIT',
    FETCH_BG: 'FETCH_BG',
    INITIATE_BG_STATE: 'INITIATE_BG_STATE',
    INITIATE_BG_SESSION: 'INITIATE_BG_SESSION',
    UPDATE_BG_STATE: 'UPDATE_BG_STATE',
    RECORD_BG: 'RECORD_BG',
    RESTART_SESSION: 'RESTART_SESSION',
    ABORT_SESSION: 'ABORT_SESSION',
    REDO_PREVIOUS_READING: 'REDO_PREVIOUS_READING',

    // messageBoard2
    POST_MESSAGE_2: 'POST_MESSAGE_2',
    POST_MESSAGE_STATE_2: 'POST_MESSAGE_STATE_2',

    // questionnaire
    ADD_QUESTION_LIST: 'ADD_QUESTION_LIST',
    ADD_QUESTION_LIST_STATE: 'ADD_QUESTION_LIST_STATE',
    ADD_ACTIVE_SET: 'ADD_ACTIVE_SET',
    ADD_ACTIVE_SET_STATE: 'ADD_ACTIVE_SET_STATE',
    UPDATE_ACTIVE_QUESTION: 'UPDATE_ACTIVE_QUESTION',
    RECORD_SURVEY: 'RECORD_SURVEY',
    UPDATE_ACTIVE_SET: 'UPDATE_ACTIVE_SET',
    UPDATE_QUESTIONLIST: 'UPDATE_QUESTIONLIST',
    CLEAR_ACTIVE_SET: 'CLEAR_ACTIVE_SET'
}
