import { select, selectAll } from 'd3-selection';
import { scaleLinear, scalePoint } from 'd3-scale';
import { axisBottom, axisLeft } from 'd3-axis';
import { schemeSet1, line, timeFormat, curveMonotoneX } from 'd3';

class ThePattern {
    constructor(domNodeCurrent) {
        selectAll("svg").remove();
        this.svg = select(domNodeCurrent).append('svg');
        this.svg.attr('width', '100%').attr('height', '100%');
        // this.svg.style('background-color', 'red');
        this.colors = (n) => schemeSet1[n];
        this.parseDate = (date) => {
            return timeFormat("%d %b %Y")(new Date(date));
        }
        this.margin = { top: 20, left: 40, bottom: 50, right: 30 };
        this.ticksData = [0, 54, 70, 180, 250, 450];
        this.tickColorPalete = ["rgb(220 38 38)", "rgb(248 113 113)", "rgb(74 222 128)", "rgb(250 204 21)", "rgb(251 146 60)"]
    };
    init = (axes, data, dims) => {
        this.setDims(dims); //<-----one
        this.setScales(axes); //<-------two
        this.chart = this.svg.append('g');
        this.chart.attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
        // this.chart.append('text').text(`height: ${dims.height}`);
        this.createAxes();//<-----three
        this.createBg();
        this.updateData(data);
        // this.plotChart();
    };
    // first; set up initial dimensions.
    setDims = (dims) => {
        this.dims = dims;
        this.innerHeight =
            this.dims.height - (this.margin.top + this.margin.bottom);
        this.innerWidth =
            this.dims.width - (this.margin.left + this.margin.right);
    };
    // second; set up scales using new dims
    setScales = (axes) => {
        const xAxes = axes.x;
        xAxes.push('')
        xAxes.unshift(null)
        const yAxes = axes.y || [0, 450]
        this.xScale =
            scalePoint()
                .domain(xAxes)
                .range([0, this.innerWidth]);
        this.yScale =
            scaleLinear()
                .domain(yAxes)
                .range([this.innerHeight, 0])
    };
    // third; create axis groups using the following methods.
    createAxes = () => {
        this.scaleAxes();
        this.xAxisBottomG = 
        this.chart.append('g')
            .attr('transform', `translate(0, ${this.innerHeight})`)
            // .selectAll('text')
            // .attr('transform', 'rotate(-45)')
            .call(this.xAxisBottom)
            // .selectAll('text')
            .attr('transform', 'rotate(-20)')
            .attr('text-anchor', 'end')
            // .attr('dx', '-0.8em')
            // .attr('dy', '0.15em');
        this.yAxisLeftG =
            this.svg.append('g')
                .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`)
                .call(this.yAxisLeft)
    };
    createBg = () => {
        // fill background with colors
        for (let i = 0; i < this.ticksData.length; i++) {
            const color = this.tickColorPalete[i - 1]
            const _height = this.yScale(this.ticksData[i] - this.ticksData[i - 1])
            const _y = this.yScale(this.ticksData[i])
            this.chartBg = this.chart.selectAll('.chartBg');
            this.chartBg.append('rect')
                .attr('class', 'chartBg')
                .attr("x", 0)
                .attr("y", _y)
                .attr("width", this.innerWidth)
                .attr("height", this.innerHeight - _height)
                .attr("fill", color)
                .attr("opacity", 0.5)
        }
    };
    scaleAxes = () => {
        this.xAxisBottom =
            axisBottom()
                .scale(this.xScale)
        // .tickSize(-this.innerHeight)
        this.yAxisLeft =
            axisLeft()
                .scale(this.yScale)
                .tickSize(-this.innerWidth)
                .tickPadding(10)
                .tickValues(this.ticksData)
    };
    updateData = (data) => {
        this.data = data || [];
        this.plotChart();
    };
    updateXAxis = (data) => {
        this.xAxis = data;
    };
    plotChart = (data) => {
        this.data = data || [];

        // fill background with colors
        for (let i = 0; i < this.ticksData.length; i++) {
            const color = this.tickColorPalete[i - 1]
            const _height = this.yScale(this.ticksData[i] - this.ticksData[i - 1])
            const _y = this.yScale(this.ticksData[i])
            this.chart.append('rect')
                .attr('class', 'chartBg')
                .attr("x", 0)
                .attr("y", _y)
                .attr("width", this.innerWidth)
                .attr("height", this.innerHeight - _height)
                .attr("fill", color)
                .attr("opacity", 0.5)
        }
        // tooltip position
        const div = select("#svgWrapper")
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("position", "absolute")
        // // background for legend
        // this.chart.append('rect')
        //     .attr("x", (this.innerWidth - (3 * 20)) - 80)
        //     .attr("y", 25)
        //     .attr("width", this.innerWidth/ 15)
        //     .attr("height", (this.innerHeight/14) * this.data.length)
        //     .attr("fill", 'white')
        this.data.forEach((group, i) => {
            const data = group.day_readings.filter(obj => obj.blood_glucose_reading !== null)
            // draw line
            this.chart.append('path')
                .datum(data)
                .attr('class', 'lineChart')
                .attr('fill', 'none')
                .attr('stroke', this.colors(i))
                .attr('stroke-width', 3)
                .attr('d', line()
                    .x((d, i) => {
                        return this.xScale(d.time_of_reading)
                    })
                    .y((d) => {
                        return this.yScale(d.blood_glucose_reading)
                    }
                    )
                    .curve(curveMonotoneX)
                )
            // // draw legends
            // const legend_x = (this.innerWidth - (3 * 20));
            // const legend_y = ((i) * 25)
            // this.chart.append('rect')
            //     .attr('class', 'legend')
            //     .attr("x", legend_x - 70)
            //     .attr("y", legend_y + 30)
            //     .attr("width", this.innerWidth * 0.01)
            //     .attr("height", this.innerWidth * 0.01)
            //     .attr("fill", this.colors(i))
            // this.chart.append("text")
            // .attr('class', 'legend')
            //     .attr("transform",
            //         "translate(" + (legend_x - 20) + " ," +
            //         // "translate(" + (width - (3 * 320) + (i * 200)) + " ," +
            //         (legend_y + 43) + ")")
            //     .style("text-anchor", "middle")
            //     .style('font-weight', 'bold')
            //     .attr("fill", 'black')
            //     .style('z-index', 60)
            //     .style('font-size', this.innerWidth * 0.008)
            //     .text(this.parseDate(group.reading_date))

            // eventlistners for tooltip
            const mouseover = (event, d) => {
                div.transition()
                    .duration(200)
                    .style("opacity", 1);
                div.html(`${d.time_of_reading} </br> ${d.blood_glucose_reading} mg/dL`)
                    .style("left", (event.pageX) + "px")
                    .style("top", (event.pageY - 28) + "px")
                    .style("color", "white");
            }
            const mouseout = (event, d) => {
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            };

            // draw circles on points
            data.forEach(_data => {
                this.chart
                    .append("circle")
                    .datum(_data)
                    .attr('class', 'myCircles')
                    .attr("fill", "black")
                    .attr("stroke", "none")
                    .attr("cx", (d, i) => {
                        return this.xScale(d.time_of_reading)
                    })
                    .attr("cy", (d) => {
                        return this.yScale(d.blood_glucose_reading)
                    }
                    )
                    .attr("r", 4)
                    .on("mouseleave", (e, d) => mouseout(e, d))
                    .on("mouseover", (e, d) => mouseover(e, d));
            })

        })
    };
    updateAxes = () => {
        this.scaleAxes();
        this.xAxisBottomG
            .attr('transform', `translate(0, ${this.innerHeight})`)
            // .selectAll('text')
            // .attr('transform', 'rotate(-45)')
            .call(this.xAxisBottom)
            .selectAll('text')
            .attr('transform', 'rotate(-20)')
            .attr('text-anchor', 'end')
            // .attr('dx', '-0.8em')
            // .attr('dy', '0.15em');
        this.yAxisLeftG
            .call(this.yAxisLeft)
    };
    updateDims = (axes, data, dims) => {
        this.setDims(dims);//<------------one
        this.setScales(axes);//<-----two
        this.updateAxes();//<-----------three
        this.createBg();
        this.clearPrev();
        this.plotChart(data);
    };
    clearPrev = () => {
        selectAll(".lineChart").remove();
        selectAll(".myCircles").remove();
        selectAll(".chartBg").remove();
        selectAll(".legend").remove();
    }
};
export default ThePattern;
