import React from 'react'
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Checkbox = (props) => {
    const { label, name, ...rest } = props;

    return (
        <div key={name} className='form-control space-x-2'>
            <Field id={name} name={name} type="checkbox" {...rest} />
            <label htmlFor={name}>{label}</label>
            <ErrorMessage name={name}>
                <TextError />
            </ErrorMessage>
        </div>
    )
}

export default Checkbox