import BrandLogo from '../../assets/images/brand-logo.png';

const Navbar = ({ patientName }) => {

    return (
        <nav className="w-full shadow">
            <div className="w-full flex flex-row">
                <div className="w-32 flex items-center py-1">
                    <img src={BrandLogo} alt="brand-logo" />
                </div>
                <div className="w-full py-3 md:py-3 absolute flex justify-center self-center ml-4 sm:ml-0">
                    <p className='text-md sm:text-lg md:text-3xl text-primary'>Hello {patientName}!</p>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
