import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { axiosPublic } from '../../api';
import { formatDate } from '../../utils';

const Appointments = () => {

    const { patient } = useSelector(state => state.patient)
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        if(patient.patient_id) {
            axiosPublic.get(`/appointments/${patient.patient_id}`)
                .then(res => setAppointments(res.data.appointmentDetails));
        }
    }, [patient.patient_id]);

    return (
        <div className=''>
            <div 
            className='text-md sm:text-lg flex flex-start'
            >Appointments</div>
            <div 
            className='w-100 flex flex-start flex-col mt-4 space-y-2'
            >
                {appointments.map((appt, i) =>
                    <div key={i}>
                        <div className='mt-4 flex flex-row relative space-x-2 flex-1 border-2 border-primary rounded'>
                            <div className='absolute -top-3 left-1/5 p-1 text-xs text-white text-center translate-x-1/2 bg-primary rounded'>
                                {formatDate(appt.appointmentDate)}
                            </div>
                            <div className='space-y-1 flex-2 mt-4'>
                                <ApptList appointments={appt.appointments} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const ApptList = ({ appointments }) => {
    return appointments.map((appt, i) => (
        <div key={i}>
            <div className='text-xs flex flex-col justify-start space-y-[0.3rem]'>
                <div className='flex flex-row space-x-2'>
                    <p className='w-fit'>
                        {appt.providerName}
                    </p>
                    <p className='text-[0.5rem] bg-primary w-fit rounded px-1 text-white'>
                        {appt.providerRole}
                    </p>
                </div>
                <p className='w-fit'>
                    {appt.appointmentStartTime} - {appt.appointmentEndTime}
                </p>
                <p className='w-fit flex flex-row space-x-2'>
                    <FontAwesomeIcon icon={faLocationDot} className="text-customGray" />
                    <div className='whitespace-normal'>{appt.locationDetails}</div>
                </p>
            </div>
            {
                appointments.length - 1 !== i
                    ? <div className='w-full justify-self-center border-lightGray border' />
                    : null
            }
        </div>
    ))
}

export default Appointments;
