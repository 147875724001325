import { useEffect } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import BloodGlucose from './containers/BloodGlucose';
import Cardset from './containers/Cardset';
import Navbar from './containers/Navbar';
import { ACTION } from './store/action';
import Appointments from './containers/Appointments';
import ReactNode from './components/ReactNode';
import { Provider } from './ReactDims';
import Footer from './containers/Footer';
import EmergencyNotice from './components/ui/EmergencyNotice';
import CommonSnack from './containers/CommonSnack';
import { axiosPublic } from './api';
import { Form, Formik } from 'formik';
import MultipleChoiceField from './MultipleChoice';
import MultipleSelectField from './MultipleSelectField';
import FreeTextField from './FreeTextField';
import RatingField from './RatingField';

function App() {

  const dispatch = useDispatch();
  const message = useSelector(state => state.messageBoard2);
  const { activeSet } = useSelector(state => state.questionnaire);

  const questionnnaireInitialValues = {};

  const state = {
    dob: "1981-07-27",
    gender: "F",
    patient_id: "53eb8d65-ffae-4cf1-aea4-97aa76618048",
    patient_name: "Jane Doe",
    mobile_number: "+58741236"
  }

  useEffect(() => {
    axiosPublic.get(`/hb-health-framework/interventions/questionnaire/${state.patient_id}/assigned-questionnaires`)
      .then(res => {
        const questionnaireList = res.data.allAssignedQuestionnaires;
        questionnaireList.sort((a, b) => new Date(a.assignedOn) - new Date(b.assignedOn));
        dispatch({
          type: ACTION.ADD_QUESTION_LIST,
          payload: questionnaireList
        });
      });
    dispatch({
      type: ACTION.ADD_PATIENT,
      payload: state
    })
    dispatch({
      type: ACTION.FETCH_INIT
    })
  }, []);

  const getFormField = (question, values) => {
    switch (question.questionType) {
      case 'multiple-choice':
        return <MultipleChoiceField key={question.questionId} question={question} values={values} />
      case 'multi-select':
        return <MultipleSelectField key={question.questionId} question={question} values={values} />
      case 'freetext':
        return <FreeTextField key={question.questionId} question={question} values={values} />
      case 'rating':
        return <RatingField key={question.questionId} question={question} values={values} />
      default:
        return null
    }
  }

  return (
    <div className="App">
      <Navbar patientName='Jane' />
      <EmergencyNotice />
      <div
        className='flex flex-col w-100 mt-8 mx-8'
      >
        <div className='space-y-8'>
          <BloodGlucose />
          <div id='messageboard2'>
            {
              <>
              <CommonSnack isShow={message.message} >
                {message.message ? <p>{message.message}</p> : null}
              </CommonSnack>
              <CommonSnack isShow={activeSet?.questionSet} >
                {activeSet?.questionSet ?
                  <Formik
                    initialValues={questionnnaireInitialValues}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                      const questionnaireResponse = [];
                      Object.keys(values)
                        .forEach(key => {
                          const questionId = key.replace('question', '')
                          const questionObj = activeSet.questionSet.find(ques => ques.questionId == questionId)
                          switch (questionObj?.questionType) {
                            case 'multi-select':
                              for (let choiceId of values[key]) {
                                const choiceObj = questionObj.choices.find(choice => choice.id == choiceId);
                                questionnaireResponse.push({
                                  questionId,
                                  choiceId,
                                  text: choiceObj.text
                                })
                              }
                              break;
                            case 'multiple-choice':
                              const choiceObj = questionObj.choices.find(choice => choice.id == values[key]);
                              questionnaireResponse.push({
                                questionId,
                                choiceId: values[key],
                                text: choiceObj.text
                              })
                              break;
                            case 'freetext':
                              questionnaireResponse.push({
                                questionId,
                                choiceId: null,
                                text: values[key]
                              })
                              break
                            default:
                            // return null
                          }
                        });
                      dispatch({
                        type: ACTION.RECORD_SURVEY,
                        payload: questionnaireResponse
                      })
                    }}
                  >
                    {({ values }) => (
                      <Form className='space-y-4'>
                        <p className='flex justify-center text-2xl'>Questionnaire Survey</p><br />
                        {activeSet.questionSet
                          ? activeSet.questionSet.map((question) => (
                            getFormField(question, values)
                          ))
                          : null
                        }
                        <div className='flex flex-row w-100 justify-evenly'>
                          <div className=''>
                            <button type='submit' className="bg-primary text-white font-bold py-2 px-4 rounded">Save</button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  : null}
              </CommonSnack>
              </>
            }
          </div>
          <Cardset />
          <Appointments />
        </div>
      </div>
      <Footer />
    </div >
  );
}

export default App;
