import Input from "./Input";
import Checkbox from "./Checkbox";
import Textarea from "./Textarea";
import Select from "./Select";
import DatePicker from "./DatePicker";

const FormikControl = (props) => {
    const { control, ...rest } = props;
    switch (control) {
        case 'input': return <Input type='input' {...rest} />
        case 'textarea': return <Textarea {...rest} />
        case 'checkbox': return <Checkbox {...rest} />
        case 'select': return <Select {...rest} />
        case 'date': return <DatePicker {...rest} />
        case 'radio':
        default: return null
    }
}

export default FormikControl;
