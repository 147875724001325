import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withContext } from '../../ReactDims';
import ThePattern from '../../chart-config/ThePattern';

const ReactNode = ({dims, data, axes})=>{
    const domNode = useRef(null);
    const [canvas, createCanvas] = useState(null);
    const [vizInitialized, setVizInitialized]= useState(false);
    // if(!dims.width) dims.width=300;
    useEffect(()=>{
      createCanvas(()=>new ThePattern(domNode.current));
    },[]);
    useEffect(()=>{
      if(data && axes && dims.width && vizInitialized===false){
        canvas.init(axes, data, dims);
        setVizInitialized(()=>true);
      };
    },[data, dims, axes]);
    useEffect(()=>{
        vizInitialized && canvas.updateDims(axes, data, dims);
      },[dims, data])
    return (
      <div ref={domNode} id='svgWrapper' style={{display: 'grid', height: '100%'}}/>
    )
  };
export default withContext(ReactNode);
