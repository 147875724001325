import { combineReducers } from 'redux';
import { reducer as patientBG } from './patientBG/reducer';
import { reducer as patient } from './patient/reducer';
import { reducer as messageBoard2 } from './messageBoard2/reducer';
import { reducer as questionnaire } from './questionnaire/reducer';

export default combineReducers({
    patientBG: patientBG,
    patient: patient,
    messageBoard2: messageBoard2,
    questionnaire: questionnaire
})