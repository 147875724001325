import React from 'react';
import { useSelector } from 'react-redux';
import { Provider } from '../../ReactDims';
import ReactNode from '../../components/ReactNode';
import BloodGlucoseChart from '../../charts/BloodGlucoseChart';

const ChartWrapper = () => {

  const { blood_glucose_readings, reading_sessions } = useSelector(state => state.patientBG);

  return (
    <div className='grid h-72 md:h-96 grid-rows-60px-1fr-1fr-60px grid-cols-60px-1fr-1fr-60px text-xs sm:text-base
    '>
      <div className='row-start-1 col-start-1 row-end-4 col-end-4'>
        <Provider>
            {/* soon we'll put our chart here! */}
            <ReactNode className='w-full' data={blood_glucose_readings} axes={{x: reading_sessions}} />
        </Provider>
      </div>
    </div>
  )
}

export default ChartWrapper;
