import { all, fork } from 'redux-saga/effects';
import { watchPatient } from './patient/saga';
import { watchPatientBG } from './patientBG/saga';
import { watchMessage2 } from './messageBoard2/saga';
import { watchQuestionnaire } from './questionnaire/saga';

function* rootSaga() {
    yield all([
        fork(watchPatientBG),
        fork(watchPatient),
        fork(watchMessage2),
        fork(watchQuestionnaire)
    ]);
}

export default rootSaga;
