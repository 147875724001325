import { Field } from 'formik';

const MultipleChoiceField = ({ question, values }) => (
  <div>
    <h3>{question.questionText}</h3>
    <div role="group" className='flex flex-col' aria-labelledby={question.questionText}>
      {question.choices.map((choice) => (
        <label key={choice.id}>
          <Field
            id={choice.id}
            type="radio"
            name={`question${question.questionId}`}
            value={choice.id}
            checked={choice.id.toString() === values[`question${question.questionId}`]}
          />
          {choice.text}
        </label>
      ))}
    </div>
  </div>
);

export default MultipleChoiceField;
