import { ACTION } from '../action';

const initialState = {
    message: '',
    isActive: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.POST_MESSAGE_STATE_2:
            return {
                ...action.state
            }
        default:
            return state;
    }
}
