import React from 'react';
import { Field } from 'formik';

const RatingField = ({ question, values }) => (
    <div>
      <h3>{question.questionText}</h3>
      <div role="group" className='flex flex-row justify-between' aria-labelledby={question.questionText}>
        {question.choices.map((choice) => (
          <label key={choice.id} className="inline-flex items-center mb-4 flex flex-col mt-2">
            <Field
              id={choice.id}
              type="radio"
              className="form-radio h-4 w-4 border-gray-300 rounded-none"
              name={`question${question.questionId}`}
              value={choice.id}
              checked={choice.id.toString() === values[`question${question.questionId}`]}
            />
            <p>{choice.text}</p>
          </label>
        ))}
      </div>
    </div>
  );

export default RatingField;
