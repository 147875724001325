import { ACTION } from '../action';

const initialState = {
    questionList: [],
    activeSet: null,
    activeQuestion: null
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.ADD_QUESTION_LIST_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case ACTION.UPDATE_ACTIVE_SET:
            return {
                ...state,
                ...action.payload
            }
        case ACTION.CLEAR_ACTIVE_SET:
            return {
                ...state,
                activeSet: null
            }
        default:
            return state;
    }
}
