import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Input = (props) => {
  const { label, name, ...rest } = props;
  return (
    <div className='form-control w-full'>
      <label htmlFor={name} className='flex justify-self-start'>{label}</label>
      <Field id={name} name={name} className='rounded-md border-2 border-border w-full' autoComplete='off' {...rest} />
      <ErrorMessage name={name}>
        <TextError />
      </ErrorMessage>
    </div>
  )
}

export default Input;
