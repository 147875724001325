import React from 'react';
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const DatePicker = (props) => {
    const { label, name, ...rest } = props;

    return (
        <div className='form-control'>
            <label htmlFor={name} className='flex justify-self-start'>{label}</label>
            <div className='rounded-md border-2 border-[#E8E8E8]'>
                <Field name={name}>
                    {
                        ({ form, field }) => {
                            const { setFieldValue } = form
                            const { value } = field
                            return <DateView
                                id={name}
                                {...field}
                                {...rest}
                                selected={value}
                                onChange={val => setFieldValue(name, val)}
                            />
                        }
                    }
                </Field>
            </div>
            <ErrorMessage name={name}>
                <TextError />
            </ErrorMessage>
        </div>
    )
}

export default DatePicker;
