import React from 'react'

const EmergencyNotice = () => {
  return (
    <div className='bg-orange-500 text-xs font-bold p-2 text-white'>
        <p>Not feeling well? call the XYZ nursing hot line at XXX-XXX-XXXX. If this is an emergency, call 911</p>
    </div>
  )
}

export default EmergencyNotice
