import { Field } from 'formik';

const FreeTextField = ({ question, values }) => (
  <div>
    <h3>{question.questionText}</h3>
    <div role="group" className='flex flex-col' aria-labelledby={question.questionText}>
    <Field as='textarea' 
        id={question.questionId}
        name={`question${question.questionId}`}
        // value={question.questionId}
        className='rounded-md border-2 border-border w-full text-black px-2'
    />
    </div>
  </div>
);

export default FreeTextField;
