import { takeEvery, call, put, select, all, takeLatest } from 'redux-saga/effects';
import { ACTION } from '../action';
import { axiosPublic } from '../../api';
import party from 'party-js';

function* addQuestionList({ payload }) {    const { patient } = yield select(state => state.patient);
    const resp = {};
    resp.questionList = yield all(payload.map(async quesSet => {
        const { data } = await axiosPublic.get(`/hb-health-framework/interventions/questionnaire/${patient.patient_id}/assigned-questionnaires/${quesSet.assignedQuestionnaireId}`);
        quesSet.questionSet = data.questionnaireDetails;
        quesSet.triggerId = data.triggerId;
        quesSet.id = quesSet.assignedQuestionnaireId;
        return quesSet;
    }))
    resp.activeSet = resp.questionList.shift();
    yield put({
        type: ACTION.ADD_QUESTION_LIST_STATE,
        payload: resp
    });
}

function* recordSurvey({ payload }) {
    try {
        const { patient } = yield select(state => state.patient);
        const { activeSet, questionList } = yield select(state => state.questionnaire);
        yield call(axiosPublic.post, `/hb-health-framework/interventions/questionnaire/${patient.patient_id}/assigned-questionnaires/${activeSet.id}`, {
            triggerId: activeSet.triggerId,
            questionnaireResponse: payload
        })
        party.confetti(document.getElementById('messageboard2'), {
            count: party.variation.range(40, 40),
        });
        if(questionList.length) {
            yield put({
                type: ACTION.UPDATE_ACTIVE_SET,
                payload: {
                    activeSet: questionList.shift()
                }
            });
            yield put({
                type: ACTION.UPDATE_QUESTIONLIST,
                payload: {
                    questionList: questionList
                }
            })
        } else {
            yield put({
                type: ACTION.CLEAR_ACTIVE_SET,
            });
            yield put({
                type: ACTION.POST_MESSAGE_2,
                payload: {
                    message: "Thanks for completing your survey"
                }
            })
        }
    } catch(e) {
        console.log("error: ", e)
        alert("error occured")
    }
}

export function* watchQuestionnaire() {
    yield takeEvery(ACTION.ADD_QUESTION_LIST, addQuestionList);
    yield takeLatest(ACTION.RECORD_SURVEY, recordSurvey);
}