import React from 'react';
import { useSelector } from 'react-redux';

const CommonSnack = (props) => {
  // const message = useSelector(state => state.messageBoard2);
  // const { activeSet } = useSelector(state => state.questionnaire);

  return (
    <div className={`scale-100 ${ props.isShow ? 'opacity-100 p-4 my-4' : 'opacity-0'} w-full transition duration-150 ease-in-out origin-left bg-orange-500 rounded text-white`} >
      {props.children}
    </div>
  )
}

export default CommonSnack;
