import { takeEvery, call, put, select } from 'redux-saga/effects';
import { ACTION } from '../action';
import { axiosPublic } from '../../api';
// import { sendTransactionsToBackend } from './api';

function* addPatient({ payload }) {
    yield put({
        type: ACTION.ADD_PATIENT_STATE, patient: payload
    });
}

export function* watchPatient() {
    yield takeEvery(ACTION.ADD_PATIENT, addPatient);
}