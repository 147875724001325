import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { formatCustomDate } from '../../utils';
import { ACTION } from '../../store/action';
import FormikControl from '../../components/formik/FormikControl';

const MoreOption = () => {
    const apptInitValues = {
        startDate: new Date(),
        readingSetType: 'Basic'
    }
    
    const glucoseSetOptions = [
        { key: 'Basic', value: 'Basic' },
        { key: 'Post-Prandial', value: 'Post-Prandial' },
        { key: 'Super-Set', value: 'Super-Set' },
        { key: 'Fasting Only', value: 'Fasting-Only' }
    ]

    const { patientBG } = useSelector(state => state);
    const dispatch = useDispatch();

    const handleAddBg = (startDate, type) => {
        dispatch({
            type: ACTION.INITIATE_BG_SESSION,
            payload: {
                startDate: formatCustomDate(startDate, 'yyyy-mm-dd'),
                readingSetType: type,
            }
        });
    }

    return (
        <div className='flex justify-between'>
            {!patientBG.fetch_next_session ?
                <div className='flex flex-row py-1'>
                    <Formik
                        initialValues={apptInitValues}
                        // validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            handleAddBg(values.startDate, values.readingSetType)
                        }}
                    >
                        {({ isSubmitting, submitForm }) => (
                            <Form className='flex flex-row justify-end space-x-1 items-center'>
                                <div>
                                <FormikControl control='select' name='readingSetType' options={glucoseSetOptions} defaultValue="Glucose Set Type" />
                                </div>
                                <div className="cursor-pointer flex justify-end w-2/4">
                                    <FormikControl key='startDate' className="w-3/4" control='date' name='startDate' />
                                </div>
                                <div className="border-2 border-[#E8E8E8] px-2 rounded" onClick={() => submitForm()}>
                                    <FontAwesomeIcon icon={faCheck} className='text-sm text-customGray' />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div> :
                <DropDown />
            }
        </div>
    )
}

const DropDown = () => {

    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(prev => !prev);
    }

    const handleRestart = () => {
        dispatch({
            type: ACTION.RESTART_SESSION
        })
        toggleDropdown();
    }

    const handleAbort = () => {
        dispatch({
            type: ACTION.ABORT_SESSION
        })
        toggleDropdown();
    }

    return (
        <>
            <div className="group inline-block">
                <div
                    className="outline-none focus:outline-none px-0 py-1 bg-white rounded-sm flex items-center min-w-32"
                    onClick={() => toggleDropdown()}
                >
                    <FontAwesomeIcon icon={faEllipsisVertical} className="text-customGray" />
                </div>
                <ul
                    className={`bg-white border rounded-sm  ${isOpen ? 'scale-100' : 'scale-0'} absolute 
  transition duration-150 ease-in-out origin-top min-w-32 right-0 text-xs`}
                >
                    <li className="rounded-sm px-3 py-1 hover:bg-gray-100" onClick={() => { if (window.confirm('Restart current glucose set?')) { handleRestart() }; }}>Restart set</li>
                    <li className="rounded-sm px-3 py-1 hover:bg-gray-100" onClick={() => { if (window.confirm('Abort current glucose set?')) { handleAbort() }; }}>Abort set</li>
                    <li className="rounded-sm relative px-3 py-1 hover:bg-gray-100 text-gray-400">Edit<span>(WIP)</span></li>
                </ul>
            </div>

        </>
    )
}

export default MoreOption;
