import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { faFile, faFileVideo, faImage, faVolumeHigh, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { getColorClass } from '../../utils';
import { axiosPublic } from '../../api/index';
import { ACTION } from '../../store/action';

const Card = ({ id, card }) => {

    const [isCardOpen, setIsCardOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [goals, setGoals] = useState({});
    const [resources, setResources] = useState([]);

    const { patient } = useSelector(state => state.patient);
    const dispatch = useDispatch();

    const statusTemplate = {
        "to_be_done": {
            "name": "To be done",
            "rank": 0,
            "color": "white",
            "statusId": 1
        },
        "abandoned": {
            "name": "Abandoned/not required",
            "rank": -1,
            "color": "grey",
            "statusId": 5
        },
        "struggling_with": {
            "name": "Struggling with",
            "rank": 1,
            "color": "red",
            "statusId": 4
        },
        "delivered": {
            "name": "Delivered/Competent",
            "rank": 3,
            "color": "green",
            "statusId": 2
        },
        "working_on": {
            "name": "Working on",
            "rank": 2,
            "color": "yellow",
            "statusId": 3
        }
    }

    useEffect(() => {
        // if (isCardOpen) {
            // setLoading(true);
            try{ 
                axiosPublic.get(`/hb-health-framework/interventions/${patient.patient_id}/goals-and-resources?card_id=${card.cardId}`)
                    .then(res => res.data)
                    .then(data => {
                        setGoals(data.goalDetails);
                        setResources(data.availableResources);
                        // setLoading(false);
                    });
            } catch (e) {
                console.log("data not found")
            }
        // }
    }, []);

    const toggleCard = () => {
        setIsCardOpen(prev => !prev);
    }

    return (
        <div key={id} className={`flex flex-no-wrap
        ${statusTemplate?.[card.status]?.color ? getColorClass(statusTemplate?.[card.status].color) : null}
        scrolling-touch items-start p-1 shadow-md rounded-md border-2 border-[#E8E8E8] cursor-pointer`} onClick={toggleCard}>
            <div className={`w-full h-92  
             font-bold p-1  mx-2 flex flex-col justify-between space-y-1`}
            >
                <div className={`w-full justify-self-center flex flex-row p-1 space-x-3`}
                >
                    <p className='text-sm sm:text-lg'>{card.cardName}</p>
                    {goals.goal && <p className='text-2xl'>&#127919;</p>}
                    {resources.length && <p className='text-2xl'>&#128451;</p>}
                    {/* <Accordion items={[{title: card.cardName}, {title: card.cardName}]} /> */}
                </div>
                {
                    isCardOpen
                        ? <div className='space-y-2'>
                            {goals.goal
                            ? <div className='flex flex-col justify-self-center p-4 bg-white shadow-md rounded-md border-2 border-[#E8E8E8]'>
                                    <p className='text-sm sm:text-lg'>{goals.goal}</p>
                                    <br />
                                    <div className='flex flex-row justify-around'>
                                        <div className='w-1/2 px-4 py-2 flex justify-end' >
                                            <div className='w-1/2 flex items-center justify-center space-x-3 shadow-md rounded-md border-2 border-[#E8E8E8] bg-customGreen'>
                                            {/* <FontAwesomeIcon icon={faThumbsDown} size='2xl' className='text-customGray' /> */}
                                            <p className='text-2xl'>&#128078;</p>
                                            <p className='text-lg mt-1'>No</p>
                                            </div>
                                        </div>
                                        <div className='w-1/2 px-4 py-2'>
                                        <div className='w-1/2 flex items-center justify-center space-x-3 shadow-md rounded-md border-2 border-[#E8E8E8] bg-customGreen'>
                                            <p className='text-2xl'>&#128077;</p>
                                            {/* <FontAwesomeIcon icon={faThumbsUp} size='2xl' className='text-yellow-200' /> */}
                                            <p className='text-lg mt-1'>Yes</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            : null}
                            {resources.length
                            ? <div className='flex flex-col justify-self-center p-2 bg-white shadow-md rounded-md border-2 border-[#E8E8E8]'>
                                <p className='text-sm sm:text-lg'>Resources:</p>
                                <div className=''>
                                {resources.map((resrc, i) => <div className='text-xs sm:text-md'>
                                    <a href='' key={i} className='flex flex-row items-center'><p className=''>{GetIconFromType(resrc.fileType)}</p><p>{resrc.fileName}</p></a>
                                </div>
                                )}
                                </div>
                            </div>
                            : null }
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

const GetIconFromType = (type) => {
    switch (type) {
    case 'pdf': return <FontAwesomeIcon icon={faFile} className='text-customGray p-2 rounded' />;
    case 'video': return <FontAwesomeIcon icon={faFileVideo} className='text-customGray p-2 rounded' />;
    case 'audio': return <FontAwesomeIcon icon={faVolumeHigh} className='text-customGray p-2 rounded' />;
    case 'image': return <FontAwesomeIcon icon={faImage} className='text-customGray p-2 rounded' />;
    default: return null;
    }
};


export default Card;

