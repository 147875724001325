import { timeFormat } from "d3-time-format"
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat"

export const convertToPercentage = (min, max, value) => (value - min) / (max - min) * 100;

export const formatDate = (date) => {
    dayjs.extend(customParseFormat)
    const locDate =  dayjs(date, "MM-DD-YYYY");
    return timeFormat("%d %b '%y")(locDate);
}

export const formatCustomDate = (date, type) => {
    var locDate = new Date(date);
    switch (type) {
        case 'yyyy-mm-dd':
            return timeFormat("%Y-%m-%d")(locDate);
        case 'mm-dd-yyyy':
            return timeFormat("%m-%d-%Y")(locDate);
        default:
            return timeFormat("%d %b '%y")(locDate);
    }
}

export const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getColorClass = (color, weight) => {
    switch (color) {
        case 'white': return 'bg-white text-black'
        case 'red': return 'bg-customRed text-black'
        case 'blue': return 'bg-blue-400 text-black'
        case 'yellow': return 'bg-customYellow text-black'
        case 'orange': return 'bg-orange-400 text-black'
        case 'green': return 'bg-customGreen text-black'
        case 'black': return 'bg-black text-black'
        case 'grey': return 'bg-customGray text-white'
        default: return 'bg-white text-black'
    }
}
